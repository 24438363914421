import React from 'react';
import { Head } from '@inertiajs/react';

interface SEOProps {
  title: string;
  description: string;
  imageUrl?: string;
  canonicalUrl?: string;
  type?: 'website' | 'article';
  fieldName?: string;
  schema?: Record<string, any>;
}

const SEO: React.FC<SEOProps> = ({
  title,
  description,
  imageUrl = '/images/logo@2.png',
  canonicalUrl,
  type = 'website',
  fieldName,
  schema
}) => {
  // Format title based on whether it's a field or not
  const formattedTitle = fieldName 
    ? `Secure Dog Walking Fields in ${fieldName} | Grow Walkies`
    : `${title} | Grow Walkies - Secure Dog Walking Fields`;

  // Format description based on whether it's a field or not
  const formattedDescription = fieldName
    ? `Book a secure, private dog walking field at Grow Walkies ${fieldName}. Safe, enclosed spaces for off-lead play, exercise, and socialisation. Easy online booking!`
    : description;

  // Base URL for constructing absolute URLs
  const baseUrl = 'https://grow-walkies.com';
  const fullImageUrl = imageUrl.startsWith('http') ? imageUrl : `${baseUrl}${imageUrl}`;
  const fullCanonicalUrl = canonicalUrl ? (canonicalUrl.startsWith('http') ? canonicalUrl : `${baseUrl}${canonicalUrl}`) : undefined;

  // Convert schema to JSON string
  const schemaJson = schema ? JSON.stringify(schema) : undefined;

  return (
    <Head>
      {/* Primary Meta Tags */}
      <title>{formattedTitle}</title>
      <meta name="title" content={formattedTitle} />
      <meta name="description" content={formattedDescription} />

      {/* Canonical URL */}
      {fullCanonicalUrl && <link rel="canonical" href={fullCanonicalUrl} />}

      {/* Open Graph / Facebook */}
      <meta property="og:type" content={type} />
      <meta property="og:url" content={fullCanonicalUrl || baseUrl} />
      <meta property="og:title" content={formattedTitle} />
      <meta property="og:description" content={formattedDescription} />
      <meta property="og:image" content={fullImageUrl} />
      <meta property="og:site_name" content="Grow Walkies" />

      {/* Twitter */}
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={fullCanonicalUrl || baseUrl} />
      <meta property="twitter:title" content={formattedTitle} />
      <meta property="twitter:description" content={formattedDescription} />
      <meta property="twitter:image" content={fullImageUrl} />

      {/* Structured Data / JSON-LD */}
      {schemaJson && (
        <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: schemaJson }} />
      )}
    </Head>
  );
};

export default SEO;